







































import { Component, Prop, Mixins } from 'vue-property-decorator'

import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, CourseProgramDescriptionsPart, GroupLargeResource, MediaResource } from '@/store/types'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'

interface CourseProgramDescriptionsForm {
  descriptions: CourseProgramDescriptionsPart[],
  programId: number[] | null,
}

@Component({
  components: {
    AttachmentsWrapper,
    ButtonIconAction,
    Tag,
    TextAreaInput,
  },
})
export default class CoursesItemGroupProgram extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  private get program() {
    return ManagerCoursesModule.program
  }

  private get courseID() {
    return +this.$route.params.courseID
  }

  private form: CourseProgramDescriptionsForm = {
    descriptions: [],
    programId: [],
  }
  private isLoaded = false
  private programCourseFile: MediaResource[] = []

  private mounted () {
    this.fetchProgram()
  }

  private fetchProgram () {
    ManagerCoursesModule.fetchProgram(this.courseID)
      .then(response => {
        this.form = {
          descriptions: response.program.map(program => ({ description: program.description, monthId: program.id })),
          programId: response.programMedia ? [response.programMedia.id] : [],
        }
        this.programCourseFile = response.programMedia ? [response.programMedia] : []
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
      .finally(() => this.isLoaded = true)
  }

  private saveProgram() {
    ManagerCoursesModule.saveProgram({
      body: {
        descriptions: this.form.descriptions,
        programId: this.form.programId && this.form.programId.length ? this.form.programId[0] : null,
      },
      courseID: this.courseID,
    })
      .then(() => this.notifySuccess('Программа курса успешно сохранена'))
      .catch(this.notifyError)
  }
}
